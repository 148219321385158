.textExpandWrapper {
  position: relative;
  margin-bottom: 20px;
  font-family: pangramregular;
  color: #646464;
  line-height: 21px;
  // width: 61%;
  // margin: 0 auto;
}

.textContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 22px;
  margin: 5px auto 0;
  letter-spacing: 0.01em;

  &.font-style {
    font-size: 0.875rem;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #646464;
    font-family: 'pangramregular';
  }

  h2 {
    font-size: 14px;
    line-height: 20px;
    font-family: 'pangrammedium';
    margin: 0 0 5px;
    letter-spacing: 0.01em;
  }

  table {
    tbody {
      tr {
        &:first-child {
          td {
            text-align: center;
            width: 50%;
          }
        }
      }

      td {
        font-size: 14px;
        color: #333;
        line-height: 23px;
        font-family: 'pangramregular';

        &:nth-child(even) {
          text-align: center;
        }

        a {
          color: #e27a34;
        }
      }
    }
  }

  a {
    color: #e37a24;
  }
}

.textContainer.expanded {
  // -webkit-line-clamp: unset;
  white-space: normal;
  height: auto;

  p {
    width: auto !important;
  }
}

.read-more-btn {
  color: #e27a34;
  z-index: 1;
  position: absolute;
  max-width: none;
  overflow: inherit;
  height: auto;
  right: 0px;
  top: 3px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  // padding: 4px;
  font-family: 'pangramregular';
  background-color: #fff;
}

.read-less {
  position: relative;
  right: -2px;
  top: 0px;
}

.read-more.has-symbols:after {
  content: '+';
  font-size: 30px;
  line-height: 8px;
  padding: 2px;
}

.read-less.has-symbols {
  position: unset !important;
}

.read-less.has-symbols:after {
  content: '-';
  font-size: 30px;
  padding: 2px;
  margin: 0 auto;
  position: unset !important;
}

.textContainer p {
  max-width: unset;
}

@media (max-width: 767px) {
  .textExpandWrapper {
    width: 95%;
    margin: 0 auto;
  }

  .textContainer {
    p {
      strong {
        font-size: 14px;
      }
    }
  }

  .read-more-btn {
    font-size: 13px;
    padding: 4px;
  }

  .textExpandWrapper {
    margin-bottom: 0;
  }

  .textContainer {
    h2 {
      color: #515151;
      font-family: 'pangramregular';
      font-size: 14px;
    }

    table {
      tbody {
        td {
          font-family: 'pangramregular';

          &:first-child {
            width: 80% !important;
          }

          &:last-child {
            width: 20% !important;
            color: #515151;
          }
        }
      }
    }
  }

  .textContainer.expanded {
    // -webkit-line-clamp: unset;
    white-space: normal;
    height: auto;

    h2 {
      font-size: 14px;
      margin: 10px 0px;
    }

    p {
      width: auto !important;
    }
  }

  .supply-read-more {
    height: 63px;
    -webkit-line-clamp: 3;
    white-space: normal;

    .read-more-btn {
      margin-top: 0px;
      top: auto;
      bottom: 4px;
    }

    .read-less {
      bottom: 1px;
    }
  }
}

.mx-650 {
  width: 650px;
  margin: 0 auto;
  position: relative;
}
